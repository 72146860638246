var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ImageSelect, ImageSelectItem, Label } from "@animech/ui";
import { useTranslation } from "react-i18next";
import * as Styled from "./grip-position-settings.style";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { useNumberConversionWithMillimeters } from "hooks/number-conversion";
import { actions, useAppDispatch } from "store";
import { GripPositionMarkerId } from "store/types";
import { gripPositionMarkerModels, ovalGripPositionDiameterRatio, } from "3d/constants/common";
import { Unit } from "utility/number";
export var GripPositionSettings = function (_a) {
    var gripPosition = _a.gripPosition;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var convertNumberWithMillimeters = useNumberConversionWithMillimeters();
    var gripPositionModel = gripPositionMarkerModels[gripPosition.marker];
    var markerSizeMinValue = gripPositionModel.diameterRange[0];
    var markerSizeMaxValue = gripPositionModel.diameterRange[1];
    var convertedMinMarkerSize = convertNumberWithMillimeters(markerSizeMinValue, Unit.M);
    var convertedMaxMarkerSize = convertNumberWithMillimeters(markerSizeMaxValue, Unit.M);
    var onChangeMarkerType = function (value) {
        dispatch(actions.setGripPositionMarker({
            nodeId: gripPosition.nodeId,
            marker: value,
        }));
    };
    var onChangeWidth = function (width) {
        dispatch(actions.setGripPositionWidth({
            nodeId: gripPosition.nodeId,
            width: gripPosition.marker === GripPositionMarkerId.CIRCLE
                ? width
                : width *
                    ovalGripPositionDiameterRatio /* the entered number is for the short side of the oval, while width in our system represents the long side */,
        }));
    };
    return (_jsx(Styled.Root, { children: _jsxs(Styled.Content, { onMouseDown: function (event) {
                event.stopPropagation();
            }, children: [_jsxs(Styled.Field, { children: [_jsxs(Label, { children: [t("sidebar.edit_grip_position.marker_type_label"), " "] }), _jsx(ImageSelect, { itemImages: Object.values(GripPositionMarkerId).map(function (positionId) { return gripPositionMarkerModels[positionId].image; }), items: Object.values(GripPositionMarkerId), value: gripPosition.marker, onValueChange: onChangeMarkerType, gridItemSize: 5, gridSpacing: "medium", gridAlign: "center", renderItem: function (props) { return (_jsx(Styled.ImageContainer, { children: _jsx(ImageSelectItem, __assign({}, props)) })); } })] }), _jsx(Styled.Field, { children: _jsx(ConvertedNumberInput, { label: t("sidebar.edit_grip_position.marker_size_label", {
                            minValue: convertedMinMarkerSize.value,
                            maxValue: convertedMaxMarkerSize.value,
                            unit: convertedMinMarkerSize.unit,
                        }), setValue: onChangeWidth, unit: Unit.M, min: markerSizeMinValue, max: markerSizeMaxValue, value: gripPosition.marker === GripPositionMarkerId.CIRCLE
                            ? gripPosition.width
                            : gripPosition.width /
                                ovalGripPositionDiameterRatio /* the displayed number is for the short side of the oval, while width in our system represents the long side */, type: "float", customConverter: convertNumberWithMillimeters, instantUpdate: true }) })] }) }));
};
