import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Styled from "ui/components/panels/sidebar/sidebar.style";
import { systemDataConstants } from "api/system-data";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { KeyBindings, useKeyBindings } from "hooks/keybindings";
import { useNumberConversion } from "hooks/number-conversion";
import { Unit } from "utility/number";
import { MultipleValuesDropdown } from "ui/components/elements/input/multiple-values-dropdown";
export var CentralizedVacuumPumpMenu = function (_a) {
    var nodes = _a.nodes;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    useKeyBindings(nodes[0], [KeyBindings.DELETE, KeyBindings.ESCAPE]);
    var convertNumber = useNumberConversion();
    var nodeIds = nodes.map(function (node) { return node.id; });
    var onSelectPump = function (pump) {
        dispatch(actions.selectCentralPump({
            nodeIds: nodeIds,
            value: pump,
        }));
    };
    var currentlySelectedPumpValues = useAppSelector(selectors.selectSelectedCentralPumps(nodeIds)).map(function (pump) { return pump === null || pump === void 0 ? void 0 : pump.value; });
    var pumpOptions = useMemo(function () {
        if (!(systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.vacuumPumpComponents))
            return [];
        return Object.values(systemDataDefineParameters.vacuumPumpComponents).filter(function (component) {
            return (component.valid ||
                currentlySelectedPumpValues.includes(component.value)) &&
                /* currentlySelectedPump will be undefined if there has been an error and no pump
                 * has been successfully committed to Tacton. This is the only time we want to show the
                 * "no pump selected" option */
                (!currentlySelectedPumpValues.length ||
                    currentlySelectedPumpValues.every(function (pump) { return pump === undefined; }) ||
                    component.value !==
                        systemDataConstants.parameterValues.noPumpSelected);
        });
    }, [
        currentlySelectedPumpValues,
        systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.vacuumPumpComponents,
    ]);
    var formattedItemLabels = pumpOptions.map(function (component) {
        return component.label +
            " (rec ".concat(convertNumber(Number(component.recFeedPressureMpa), Unit.MPA).formatted, ")");
    });
    return (_jsx(_Fragment, { children: pumpOptions && (_jsx(Styled.SidebarSection, { children: _jsx(MultipleValuesDropdown, { label: t("sidebar.edit_pump.pump_dropdown_label"), items: pumpOptions.map(function (pump) { return pump.value; }), itemLabels: formattedItemLabels, values: currentlySelectedPumpValues, onValueChange: onSelectPump }) })) }));
};
