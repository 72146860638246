var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import * as Styled from "./graph.style";
import { AirConsumptionGraph as AirConsumptionGraph } from "./air-consumption-graph";
import { RobotMotionGraph as RobotMotionGraph } from "./robot-motion-graph";
import { VacuumPumpGraph } from "./vacuum-pump-graph";
import { HorizontalZoomSlider } from "./horizontal-zoom-slider";
import { ChartBackground } from "./common/custom-chart-background";
import { LiftingForcesGraph } from "ui/components/panels/graphs/lifting-forces-graph";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { useBlockingElementEffect } from "hooks/playcanvas/blocking-element-effect";
import { InfoButton } from "ui/components/elements/info-button";
import { NodeList } from "ui/components/panels/graphs/common/node-list";
import { useGraphDataContext } from "providers/graph-panel";
export var GraphPanel = function () {
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var _a = useGraphDataContext(), zoomOut = _a.zoomOut, printModeActive = _a.printModeActive;
    var isGraphPanelOpen = useAppSelector(selectors.selectIsGraphPanelOpen);
    var _b = __read(useState(false), 2), shouldRenderGraphData = _b[0], setShouldRenderGraphData = _b[1];
    var onTransitionEndEvent = function (e) {
        if (e.propertyName !== "transform")
            return;
        if (isGraphPanelOpen)
            return;
        setShouldRenderGraphData(false);
    };
    useEffect(function () {
        if (!isGraphPanelOpen)
            return;
        setShouldRenderGraphData(true);
    }, [isGraphPanelOpen]);
    return (_jsxs(Styled.GraphPanel, { isOpen: isGraphPanelOpen, printMode: printModeActive, onTransitionEnd: onTransitionEndEvent, children: [_jsxs(Styled.TopBar, { children: [_jsxs(Styled.ZoomButtonsContainer, { className: "zoom-buttons-container", children: [_jsx(Styled.ZoomButton, { appearance: "text", icon: "zoom-out", rounded: true, onClick: zoomOut, children: t("graph_panel.zoom_out") }), _jsx(Styled.ZoomButton, { appearance: "text", icon: "zoom-in", rounded: true, disabledHoverEffect: true, children: t("graph_panel.zoom_in") })] }), _jsxs(Styled.ButtonsContainer, { children: [_jsx(InfoButton, { title: t("dashboard.application_cycle_data"), description: {
                                    long: t("descriptions.dashboard.application_cycle_data"),
                                } }), _jsx(Styled.CloseButton, { appearance: "text", color: "neutral", icon: "close", rounded: true, size: "default", onClick: function () { return dispatch(actions.toggleGraphPanel(false)); } })] })] }), shouldRenderGraphData && _jsx(GraphDataContainer, {})] }));
};
var GraphDataContainer = function () {
    var _a = useGraphDataContext(), graphPanelRef = _a.graphPanelRef, setResponsiveContainerWidth = _a.setResponsiveContainerWidth;
    var _b = useGraphDataContext(), zoomAreaLeftX = _b.zoomAreaLeftX, zoomAreaRightX = _b.zoomAreaRightX;
    useBlockingElementEffect();
    var isUserZooming = zoomAreaLeftX !== undefined || zoomAreaRightX !== undefined;
    return (_jsxs(Styled.GraphContainer, { ref: graphPanelRef, children: [_jsx(ResponsiveContainer, { width: "100%", height: "auto", id: "grafen", onResize: function (width, _) { return setResponsiveContainerWidth(width); }, children: _jsxs(Styled.GraphSection, { children: [_jsx(ChartBackground, { children: _jsx(RobotMotionGraph, {}) }), _jsx(ChartBackground, { children: _jsx(AirConsumptionGraph, {}) }), _jsx(ChartBackground, { children: _jsx(VacuumPumpGraph, {}) }), _jsx(ChartBackground, { marginBottom: 60, children: _jsx(LiftingForcesGraph, {}) }), !isUserZooming && _jsx(HorizontalZoomSlider, {})] }) }), _jsx(NodeList, {})] }));
};
