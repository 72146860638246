var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _this = this;
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import * as pc from "playcanvas";
import { getComponent } from "@animech/platform/utilities/playcanvas";
import { CardboardTapeModelId } from "ui/components/panels/canvas/cardboard-builder/types";
import { useAssetLoader } from "hooks/playcanvas/asset-loader";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { TubeType } from "store/types";
import { gripPositionMarkerModels, gripPositionMarkerMaterial, gripPositionMarkerAssetScale, cupPlaceholderUp, fittingPlaceholderTube, decentralizedVacuumPumps, cupMountingModels, fittingPlaceholderCupMounting, cupMountingPlaceholderTube, defaultDecentralPumpModel, defaultSuctionCupModel, } from "3d/constants/common";
import { applyMaterialTransforms, getMaterialAssets } from "3d/helpers";
import { useCollisionsContext } from "providers/collisions";
var CUP_MODELS_ENTITY_NAME = "SuctionCupModels";
var MARKER_ENTITY_NAME = "Marker";
export var GripPosition = function (_a) {
    var app = _a.app, nodeEntity = _a.nodeEntity, modelAssets = _a.modelAssets, materialAssets = _a.materialAssets, gripPosition = _a.gripPosition;
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var _b = useCollisionsContext(), addCollision = _b.addCollision, removeCollision = _b.removeCollision, resetCollision = _b.resetCollision;
    var loadAssets = useAssetLoader(app).loadAssets;
    var dispatch = useAppDispatch();
    var suctionCupModels = useAppSelector(selectors.selectSuctionCupModels);
    var selectedCup = useAppSelector(selectors.selectSelectedCup(gripPosition.nodeId));
    var selectedCupMounting = useAppSelector(selectors.selectSelectedCupMounting(gripPosition.nodeId));
    var selectedPump = useAppSelector(selectors.selectSelectedDecentralPump(gripPosition.nodeId));
    var cupValue = selectedCup === null || selectedCup === void 0 ? void 0 : selectedCup.value;
    var cupWidth = selectedCup === null || selectedCup === void 0 ? void 0 : selectedCup.width;
    var cupFamily = selectedCup === null || selectedCup === void 0 ? void 0 : selectedCup.cupFamily;
    var cupMountingValue = selectedCupMounting === null || selectedCupMounting === void 0 ? void 0 : selectedCupMounting.value;
    var hasSelectedPump = selectedPump !== undefined;
    var selectedPumpCadName = selectedPump === null || selectedPump === void 0 ? void 0 : selectedPump.cadName;
    /* Grip position added or removed */
    useEffect(function () {
        var markerEntity = new pc.Entity(MARKER_ENTITY_NAME);
        markerEntity.addComponent("model", { type: "asset" });
        nodeEntity.addChild(markerEntity);
        return function () {
            markerEntity.destroy();
        };
    }, [app.root, gripPosition.nodeId, nodeEntity]);
    /* Grip position size changed */
    useEffect(function () {
        var markerEntity = nodeEntity.findByName(MARKER_ENTITY_NAME);
        if (!markerEntity)
            return;
        var scale = gripPosition.width / gripPositionMarkerAssetScale;
        markerEntity.setLocalScale(new pc.Vec3(scale, 1, scale));
    }, [gripPosition.width, nodeEntity]);
    /* Grip position marker changed */
    useEffect(function () {
        var markerEntity = nodeEntity.findByName(MARKER_ENTITY_NAME);
        if (!markerEntity)
            return;
        var modelAsset = modelAssets[gripPositionMarkerModels[gripPosition.marker].modelName];
        var material = materialAssets[gripPositionMarkerMaterial]
            .resource;
        if (markerEntity.model) {
            markerEntity.model.asset = modelAsset;
            markerEntity.model.meshInstances[0].material = material;
        }
    }, [gripPosition.marker, nodeEntity, modelAssets, materialAssets]);
    /* Grip position models changed */
    useEffect(function () {
        var _a, _b, _c, _d;
        var cupModelsEntity = new pc.Entity(CUP_MODELS_ENTITY_NAME);
        nodeEntity.addChild(cupModelsEntity);
        var modelStack = [];
        var assets = [];
        var updateModelStackAndAsset = function (modelAsset) {
            if (!modelAsset.asset)
                return;
            modelStack.push(modelAsset);
            assets.push(modelAsset.asset);
            if (modelAsset.assetMaterialTransforms) {
                assets.push.apply(assets, __spreadArray([], __read(Object.values(modelAsset.assetMaterialTransforms)), false));
            }
        };
        var isCollisionWithTape = function (entityName) {
            return Object.values(CardboardTapeModelId).some(function (modelId) {
                return entityName.includes(modelId);
            });
        };
        if (cupValue && cupWidth) {
            var suctionCup = suctionCupModels[cupValue];
            if (!suctionCup && cupFamily) {
                suctionCup = suctionCupModels[cupFamily];
            }
            if (!suctionCup) {
                suctionCup = defaultSuctionCupModel;
            }
            if (suctionCup) {
                var cupAsset = modelAssets[suctionCup.cupModelName];
                var assetMaterialTransforms = getMaterialAssets(materialAssets, suctionCup.materialTransforms);
                /*--- Collision Components ---*/
                var collisionComponentType = (_a = suctionCup.collisionComponentType) !== null && _a !== void 0 ? _a : "sphere";
                cupModelsEntity.addComponent("collision", {
                    type: collisionComponentType,
                });
                cupModelsEntity.addComponent("rigidbody", {
                    type: pc.RIGIDBODY_TYPE_KINEMATIC,
                    friction: 0,
                    restitution: 0,
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var onCollisionStart = function (collisionResult) {
                    if (!isCollisionWithTape(collisionResult.other.name)) {
                        return;
                    }
                    addCollision(gripPosition.nodeId, collisionResult.other);
                };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var onCollisionEnd = function (collisionResult) {
                    if (!isCollisionWithTape(collisionResult.name)) {
                        return;
                    }
                    removeCollision(gripPosition.nodeId, collisionResult);
                };
                (_b = cupModelsEntity.collision) === null || _b === void 0 ? void 0 : _b.on("collisionstart", onCollisionStart, _this);
                (_c = cupModelsEntity.collision) === null || _c === void 0 ? void 0 : _c.on("collisionend", onCollisionEnd, _this);
                /*--- Collision Components END ---*/
                updateModelStackAndAsset({
                    asset: cupAsset,
                    placeholderName: cupPlaceholderUp,
                    assetMaterialTransforms: assetMaterialTransforms,
                    scale: cupWidth / suctionCup.cupWidth,
                    collisionComponentType: (_d = suctionCup.collisionComponentType) !== null && _d !== void 0 ? _d : "sphere",
                });
                var fittingAsset = modelAssets[suctionCup.fittingModelName];
                updateModelStackAndAsset({
                    asset: fittingAsset,
                    placeholderName: cupMountingValue
                        ? fittingPlaceholderCupMounting
                        : fittingPlaceholderTube,
                });
            }
        }
        if (cupMountingValue) {
            var cupMounting = cupMountingModels[cupMountingValue];
            var cupMountingAsset = modelAssets[cupMounting];
            updateModelStackAndAsset({
                asset: cupMountingAsset,
                placeholderName: cupMountingPlaceholderTube,
            });
        }
        if (hasSelectedPump && !isCentralizedSystem) {
            var pumpModel = (selectedPumpCadName &&
                decentralizedVacuumPumps[selectedPumpCadName]) ||
                defaultDecentralPumpModel;
            var pumpAsset = pumpModel && modelAssets[pumpModel.modelName];
            updateModelStackAndAsset({
                asset: pumpAsset,
                placeholderName: pumpModel.placeholderTube,
            });
        }
        var updateCup = function () {
            var previousPlaceholder = undefined;
            var prevEntity;
            modelStack.forEach(function (model) {
                var _a;
                var scale = model.scale, collisionComponentType = model.collisionComponentType, placeholderName = model.placeholderName, asset = model.asset;
                var entity = new pc.Entity("".concat(placeholderName, "-").concat(uuidv4()));
                cupModelsEntity.addChild(entity);
                entity.addComponent("model", { type: "asset", asset: asset });
                applyMaterialTransforms(entity, model.assetMaterialTransforms);
                if (scale) {
                    entity.setLocalScale(scale, scale, scale);
                }
                // If the model has a collisionComponentType we scale the collision model based on the model aabb.
                if (collisionComponentType) {
                    var collisionComponent = getComponent(cupModelsEntity, "collision");
                    var aabb_1 = new pc.BoundingBox();
                    (_a = entity.model) === null || _a === void 0 ? void 0 : _a.model.meshInstances.forEach(function (mi, i) {
                        if (i === 0) {
                            aabb_1.copy(mi.aabb);
                        }
                        else {
                            aabb_1.add(mi.aabb);
                        }
                    });
                    var halfExtents = aabb_1.halfExtents;
                    if (collisionComponentType === "sphere") {
                        collisionComponent.radius = halfExtents.x;
                    }
                    else if (collisionComponentType === "capsule") {
                        collisionComponent.radius = halfExtents.y * 2;
                        collisionComponent.axis = 0;
                        collisionComponent.height = halfExtents.x * 2;
                    }
                }
                if (previousPlaceholder) {
                    var placeholder = prevEntity === null || prevEntity === void 0 ? void 0 : prevEntity.findByName(previousPlaceholder);
                    if (placeholder) {
                        entity.setPosition(placeholder.getPosition());
                    }
                }
                previousPlaceholder = placeholderName;
                prevEntity = entity;
            });
            if (previousPlaceholder && prevEntity) {
                dispatch(actions.setNodeAttachments({
                    nodeId: gripPosition.nodeId,
                    placeholders: [
                        {
                            name: previousPlaceholder,
                            entityName: prevEntity.name,
                            tubeType: isCentralizedSystem
                                ? TubeType.VACUUM
                                : TubeType.COMPRESSED_AIR,
                        },
                    ],
                }));
            }
        };
        loadAssets(assets, updateCup);
        return function () {
            var _a, _b;
            cupModelsEntity.destroy();
            resetCollision(gripPosition.nodeId);
            (_a = cupModelsEntity.collision) === null || _a === void 0 ? void 0 : _a.off("collisionstart");
            (_b = cupModelsEntity.collision) === null || _b === void 0 ? void 0 : _b.off("collisionend");
        };
    }, [
        cupValue,
        cupWidth,
        cupFamily,
        hasSelectedPump,
        selectedPumpCadName,
        dispatch,
        gripPosition.nodeId,
        isCentralizedSystem,
        loadAssets,
        materialAssets,
        modelAssets,
        nodeEntity,
        cupMountingValue,
        suctionCupModels,
        addCollision,
        removeCollision,
        resetCollision,
    ]);
    return null;
};
