var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material } from "3d/types";
/** ---------------   **/
/**    B-XP Family    **/
/** ---------------   **/
var cupModelTemplate = {
    B15XP: {
        cupModelName: "B15XP",
        cupWidth: 0.016,
        cupHeight: 0.0198,
        fittingModelName: "MM5M-S1",
    },
    B35XP: {
        cupModelName: "B35XP",
        cupWidth: 0.037,
        cupHeight: 0.0246,
        fittingModelName: "G38M01-S10",
    },
    B75XP: {
        cupModelName: "B75XP",
        cupWidth: 0.0775,
        cupHeight: 0.0343,
        fittingModelName: "G38M01-S8",
    },
};
var materialTransformTemplate = {
    PU60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU60,
            },
        ],
    },
    PU30_60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU30,
            },
        ],
    },
    PU60_SEMI_CONDUCTIVE: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60SC },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU60SC,
            },
        ],
    },
};
export var suctionCupsFamilyB_XP = {
    // B10XP PU60SC
    suction_cup_0244984: __assign(__assign(__assign({}, cupModelTemplate.B15XP), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "MM5M-S3" }),
    // B10XP PU60
    suction_cup_0205168: __assign(__assign({}, cupModelTemplate.B15XP), materialTransformTemplate.PU60),
    // B10XP PU30/60
    suction_cup_0204977: __assign(__assign({}, cupModelTemplate.B15XP), materialTransformTemplate.PU30_60),
    // B15XP PU60SC
    suction_cup_0245002: __assign(__assign(__assign({}, cupModelTemplate.B15XP), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "MM5M-S3" }),
    // B15XP PU60
    suction_cup_0205171: __assign(__assign({}, cupModelTemplate.B15XP), materialTransformTemplate.PU60),
    // B15XP PU30/60
    suction_cup_0204991: __assign(__assign({}, cupModelTemplate.B15XP), materialTransformTemplate.PU30_60),
    // B20XP PU60SC
    suction_cup_9982617: __assign(__assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "G38M01-S9" }),
    // B20XP PU60
    suction_cup_0205179: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60),
    // B20XP PU30/60
    suction_cup_0205046: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU30_60),
    // B25XP PU60SC
    suction_cup_9982501: __assign(__assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "G38M01-S9" }),
    // B25XP PU60
    suction_cup_0205187: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60),
    // B25XP PU30/60
    suction_cup_0205050: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU30_60),
    // B35XP PU60SC
    suction_cup_9981361: __assign(__assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "G38M01-S9" }),
    // B35XP PU60
    suction_cup_0205197: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60),
    // B35XP PU30/60
    suction_cup_0205107: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU30_60),
    // B45XP PU60
    suction_cup_9982616: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60),
    // B45XP PU30/60
    suction_cup_9981276: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU30_60),
    // B52XP PU60
    suction_cup_0205205: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60),
    // B52XP PU30/60
    suction_cup_0205132: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU30_60),
    // B65XP PU60
    suction_cup_9980931: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU60),
    // B65XP PU30/60
    suction_cup_9979883: __assign(__assign({}, cupModelTemplate.B35XP), materialTransformTemplate.PU30_60),
    // B75XP PU60
    suction_cup_0205213: __assign(__assign({}, cupModelTemplate.B75XP), materialTransformTemplate.PU60),
    // B75XP PU30/60
    suction_cup_0205162: __assign(__assign({}, cupModelTemplate.B75XP), materialTransformTemplate.PU30_60),
    // B90XP PU60
    suction_cup_9980719: __assign(__assign({}, cupModelTemplate.B75XP), materialTransformTemplate.PU60),
    // B90XP PU30/60
    suction_cup_9980223: __assign(__assign({}, cupModelTemplate.B75XP), materialTransformTemplate.PU30_60),
    // B110XP PU60
    suction_cup_0205219: __assign(__assign({}, cupModelTemplate.B75XP), materialTransformTemplate.PU60),
    // B110XP PU30/60
    suction_cup_0205019: __assign(__assign({}, cupModelTemplate.B75XP), materialTransformTemplate.PU30_60),
};
