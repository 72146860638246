var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNumberConversion } from "hooks/number-conversion";
import { precision, round } from "utility/number";
import { NumberInput, } from "ui/components/elements/input/number-input";
export var ConvertedNumberInput = function (_a) {
    var valueFromProps = _a.value, minFromProps = _a.min, maxFromProps = _a.max, unitFromProps = _a.unit, _b = _a.customUnitLabels, customUnitLabels = _b === void 0 ? {} : _b, type = _a.type, setValue = _a.setValue, customConverter = _a.customConverter, label = _a.label, displayValue = _a.displayValue, props = __rest(_a, ["value", "min", "max", "unit", "customUnitLabels", "type", "setValue", "customConverter", "label", "displayValue"]);
    var defaultConverter = useNumberConversion();
    var _c = __read(useState(""), 2), convertedValueFromProps = _c[0], setConvertedValueFromProps = _c[1];
    var _d = __read(useState(undefined), 2), unit = _d[0], setUnit = _d[1];
    var _e = __read(useState(undefined), 2), convertedMin = _e[0], setConvertedMin = _e[1];
    var _f = __read(useState(undefined), 2), convertedMax = _f[0], setConvertedMax = _f[1];
    var convertFn = customConverter || defaultConverter;
    useEffect(function () {
        if (!unitFromProps) {
            setConvertedValueFromProps(valueFromProps.toString());
        }
        else {
            var _a = convertFn(valueFromProps, unitFromProps), value = _a.value, unit_1 = _a.unit;
            setConvertedValueFromProps(value.toString());
            setUnit(unit_1);
        }
    }, [
        setConvertedValueFromProps,
        setUnit,
        unitFromProps,
        valueFromProps,
        convertFn,
    ]);
    useEffect(function () {
        if (minFromProps === undefined)
            return;
        if (!unitFromProps) {
            setConvertedMin(minFromProps);
        }
        else {
            var value = convertFn(minFromProps, unitFromProps).value;
            setConvertedMin(value);
        }
    }, [setConvertedMin, minFromProps, unitFromProps, convertFn]);
    useEffect(function () {
        if (maxFromProps === undefined)
            return;
        if (!unitFromProps) {
            setConvertedMax(maxFromProps);
        }
        else {
            var value = convertFn(maxFromProps, unitFromProps).value;
            setConvertedMax(value);
        }
    }, [setConvertedMax, maxFromProps, unitFromProps, convertFn]);
    var setConvertedValue = function (convertedValue) {
        var setRoundedValue = function (val) {
            if (type === "int") {
                setValue(round(val, 0));
            }
            else {
                setValue(val);
            }
        };
        if (!unit) {
            setRoundedValue(Number(convertedValue));
        }
        else {
            var value = convertFn(Number(convertedValue), unit, false).value;
            setRoundedValue(value);
        }
    };
    return (_jsx(NumberInput, __assign({}, props, { type: "float", value: displayValue !== null && displayValue !== void 0 ? displayValue : convertedValueFromProps, min: convertedMin, max: convertedMax, setValue: setConvertedValue, unit: unit && customUnitLabels[unit] ? customUnitLabels[unit] : unit, precision: unit ? precision[unit] : undefined, label: label })));
};
