import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { Dropdown } from "ui/components/elements/input/dropdown";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { NumberType, ChoiceType, } from "api/types";
import { CustomImageSelect } from "ui/components/elements/input/image-select";
import { PageRoute } from "utility/url";
export var ParameterInput = function (_a) {
    var parameter = _a.parameter, _b = _a.hideLabel, hideLabel = _b === void 0 ? false : _b, customUnitLabels = _a.customUnitLabels;
    var isSystemDataLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var errors = useAppSelector(selectors.selectSystemDataErrors);
    var currentRoute = useLocation().pathname;
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var parameterError = errors === null || errors === void 0 ? void 0 : errors.find(function (err) { var _a, _b; return (_b = (_a = err.extra) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.includes(parameter.id); });
    var errorText = parameterError
        ? t("tacton_api_errors.".concat(parameterError === null || parameterError === void 0 ? void 0 : parameterError.type), {
            parameters: [parameter.id],
            count: 1,
        })
        : undefined;
    var setValue = useCallback(function (value) {
        var args = {
            parameters: [parameter.id],
            values: [value],
        };
        var isAppSelectionStep = currentRoute === PageRoute.SELECT_APPLICATION;
        return isAppSelectionStep
            ? dispatch(actions.setParametersSelectApplication(args))
            : dispatch(actions.setParametersDefineParameters(args));
    }, [dispatch, parameter.id, currentRoute]);
    var clearValue = function () { return dispatch(actions.clearParameters([parameter.id])); };
    if (parameter.type === NumberType.INT ||
        parameter.type === NumberType.FLOAT) {
        var numberParameter_1 = parameter;
        return (_jsx(ConvertedNumberInput, { label: hideLabel ? undefined : numberParameter_1.label, description: numberParameter_1.description, min: numberParameter_1.min, max: numberParameter_1.max, type: numberParameter_1.type, setValue: function (value) { return setValue(value.toString()); }, value: Number(numberParameter_1.value), error: errorText, lock: {
                isLocked: numberParameter_1.isLocked,
                onLock: function () { return setValue(numberParameter_1.value); },
                onUnlock: clearValue,
                isLockDisabled: numberParameter_1.lockDisabled,
            }, unit: numberParameter_1.unit, isLoading: isSystemDataLoading, customUnitLabels: customUnitLabels }));
    }
    else if (parameter.type === ChoiceType.DROPDOWN) {
        var dropdownParameter_1 = parameter;
        var options = dropdownParameter_1.options.reduce(function (acc, option) {
            var label = option.hasConflict
                ? "".concat(option.label, " - CONFLICT")
                : option.label;
            acc.items.push(option.value);
            acc.itemLabels.push(label);
            acc.itemImages.push(option.imageSource);
            return acc;
        }, {
            items: [],
            itemLabels: [],
            itemImages: [],
        });
        return (_jsx(Dropdown, { value: dropdownParameter_1.value, label: hideLabel ? undefined : dropdownParameter_1.label, error: errorText, itemLabels: options.itemLabels, items: options.items, itemImages: options.itemImages, onValueChange: setValue, lock: {
                isLocked: dropdownParameter_1.isLocked,
                onLock: function () { return setValue(dropdownParameter_1.value); },
                onUnlock: clearValue,
                isLockDisabled: dropdownParameter_1.lockDisabled,
            }, description: dropdownParameter_1.description }));
    }
    else {
        var imageSelectParameter = parameter;
        var options = imageSelectParameter.options.reduce(function (acc, option) {
            var _a;
            acc.items.push(option.value);
            acc.itemLabels.push(option.label);
            acc.itemImages.push((_a = option.imageSource) !== null && _a !== void 0 ? _a : "img/app/intro-background.jpg");
            return acc;
        }, {
            items: [],
            itemLabels: [],
            itemImages: [],
        });
        return (_jsx(CustomImageSelect, { value: imageSelectParameter.value, items: options.items, itemLabels: options.itemLabels, itemImages: options.itemImages, onValueChange: setValue, error: errorText, description: imageSelectParameter.description }));
    }
};
