var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Unit } from "utility/number";
import { useNumberConversionWithMillimeters } from "hooks/number-conversion";
var StyledSizeDescriptionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\talign-items: center;\n\tdisplay: flex;\n\tjustify-content: center;\n"], ["\n\talign-items: center;\n\tdisplay: flex;\n\tjustify-content: center;\n"])));
var StyledSizeDescription = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css("\n\t\t\talign-items: center;\n            background-color: #555B5EBB;\n            border-radius: 4px;\n\t\t\tdisplay: inline-flex;\n            font-size: ".concat(theme.typography.fontSize.xSmall, ";\n\t\t\tjustify-content: center;\n            margin: 0 ").concat(theme.dimensions.margin.xxLarge, " ").concat(theme.dimensions.margin.xSmall, " ").concat(theme.dimensions.margin.xxLarge, ";\n            padding: 0 ").concat(theme.dimensions.margin.small, ";\n            text-align: center;\n\t\t"));
});
export var SizeDescription = function (_a) {
    var size = _a.size;
    var conversions = useNumberConversionWithMillimeters();
    var sizeConverted = conversions(size, Unit.M);
    var t = useTranslation().t;
    return (_jsx(StyledSizeDescriptionContainer, { children: _jsx(StyledSizeDescription, { children: "".concat(t("sidebar.edit_grip_position.marker_size"), ": ").concat(sizeConverted.formatted) }) }));
};
var templateObject_1, templateObject_2;
