import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { GripPositionSettings } from "../node-components/grip-position-settings";
import * as Styled from "./node-buttons.style";
import { CanvasOverlay } from "./canvas-overlay";
import { SizeDescription } from "./node-size-description";
import { EditButtons } from "ui/components/elements/edit-buttons/edit-buttons";
import { GripPositionMarkerId, NodeComponentType, } from "store/types";
import { useNodeActions } from "hooks/node-actions";
import { ovalGripPositionDiameterRatio } from "3d/constants/common";
export var NodeButtons = function (_a) {
    var _b = _a.isZoomedIn, isZoomedIn = _b === void 0 ? false : _b, nodeBoundingBoxHeight = _a.nodeBoundingBoxHeight, nodeTopY = _a.nodeTopY, nodeCenterX = _a.nodeCenterX, node = _a.node;
    var actions = useNodeActions(node);
    var topActions = actions.filter(function (action) { return action !== undefined && action.isDelete; });
    var bottomActions = actions.filter(function (action) { return action !== undefined && !action.isDelete; });
    var gripPosition = node.component;
    var nodeSize = gripPosition.marker === GripPositionMarkerId.CIRCLE
        ? gripPosition.width
        : gripPosition.width / ovalGripPositionDiameterRatio;
    var isGripPosition = node.type === NodeComponentType.GRIP_POSITION;
    var buttons = isZoomedIn ? (_jsx(Styled.NodeButtons, { nodeBoundingBoxHeight: nodeBoundingBoxHeight, children: _jsx(EditButtons, { actions: actions, hasBackground: true }) })) : (_jsxs(Styled.NodeButtons, { nodeBoundingBoxHeight: nodeBoundingBoxHeight, nodeBoundingBoxMargin: isGripPosition ? 8 : undefined, children: [_jsx(EditButtons, { hasBackground: false, className: "".concat(Styled.prefix, "-top-buttons"), actions: topActions }), _jsx("div", { className: "".concat(Styled.prefix, "-bbox") }), isGripPosition && _jsx(SizeDescription, { size: nodeSize }), _jsx(EditButtons, { hasBackground: isZoomedIn, actions: bottomActions }), isGripPosition && _jsx(GripPositionSettings, { gripPosition: gripPosition })] }));
    return (_jsx(CanvasOverlay, { x: nodeCenterX, y: nodeTopY, onContextMenu: function (event) { return event.preventDefault(); }, children: buttons }));
};
