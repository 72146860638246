var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f;
/* eslint-disable camelcase */
import { suctionCupsFamilyDCF } from "./cup-families";
import { glassObjectShapes, suctionCupModelsGlass } from "./glass";
import { metalObjectShapes, suctionCupModelsMetal } from "./metal";
import { suctionCupModelsPlastic, plasticObjectShapes } from "./plastic";
import { cardboardObjectShapes, suctionCupModelsCardboard } from "./cardboard";
import { Material, } from "3d/types";
import { TubeType, GripPositionMarkerId, ObjectShapeId as MetalObjectShapeId, TubeConnectionId, } from "store/types";
/* GRIP POSITION */
export var ovalGripPositionDiameterRatio = 2;
export var gripPositionMarkerDefaultWidth = (_a = {},
    _a[GripPositionMarkerId.CIRCLE] = 0.1,
    _a[GripPositionMarkerId.OVAL] = 0.1,
    _a);
export var gripPositionMarkerAssetScale = 0.1;
export var gripPositionMarkerMaterial = "TransparentDarkBlue";
export var cupPlaceholderUp = "Placeholder-Fitting";
export var fittingPlaceholderTube = "Placeholder-Attachment";
export var defaultSuctionCupModel = __assign({}, suctionCupsFamilyDCF.DCF);
export var suctionCupModels = __assign(__assign(__assign(__assign({}, suctionCupModelsPlastic), suctionCupModelsGlass), suctionCupModelsMetal), suctionCupModelsCardboard);
export var gripPositionMarkerModels = (_b = {},
    _b[GripPositionMarkerId.CIRCLE] = {
        id: GripPositionMarkerId.CIRCLE,
        translationKey: "sidebar.edit_grip_position.markers.circle",
        image: "img/app/circle-marker-white.svg",
        modelName: "CupAreaCircle10cm",
        diameterRange: [0.002, 0.4],
    },
    _b[GripPositionMarkerId.OVAL] = {
        id: GripPositionMarkerId.OVAL,
        translationKey: "sidebar.edit_grip_position.markers.oval",
        image: "img/app/oval-marker-white.svg",
        modelName: "CupAreaOval5x10cm",
        diameterRange: [0.002, 0.4],
    },
    _b);
/* CUP MOUNTING */
export var cupMountingModels = {
    // Height adjuster 3/8
    cup_mounting_0121122: "0121122",
    // Height adjuster HA12
    cup_mounting_0200461: "0200461",
    // Height adjuster HA16
    cup_mounting_0200462: "0200462",
    // Level Compensator KSPH 3/8 fem 75mm, non rotate
    cup_mounting_0208930: "0208930",
    // Level Compensator KSPH 3/8 fem 50mm, non rotate
    cup_mounting_0208928: "0208928",
    // Level Compensator KSPH 3/8 fem 25mm, non rotate
    cup_mounting_0208926: "0208926",
    // Level Compensator LC25-80 3/8 fem
    cup_mounting_0124961: "0124961",
    // Level Compensator LC25-40 3/8 fem
    cup_mounting_0124959: "0124959",
    // Level Compensator LC12-10mm fem M5
    cup_mounting_0127103: "0127103",
    // Level Compensator LC12-25mm fem M5
    cup_mounting_0127105: "0127105",
    // Level Compensator LC16-20mm fem G1/8
    cup_mounting_0124951: "0124951",
    // Level Compensator LC16-35mm fem G1/8
    cup_mounting_0124953: "0124953",
    // Suction cup extension 200
    cup_mounting_0110663: "0110663",
    // Suction cup extension 150
    cup_mounting_0110661: "0110661",
    // Suction cup extension 110
    cup_mounting_0110659: "0110659",
    // Suction cup extension 75
    cup_mounting_0110657: "0110657",
    // Suction cup extension 50
    cup_mounting_0110655: "0110655",
    // Suction cup extension 25
    cup_mounting_0110653: "0110653",
    // Suction cup extension SE12
    cup_mounting_0200458: "0200458",
    // Suction cup extension SE16
    cup_mounting_0200459: "0200459",
    // Ball Joint fitting lockable (PMAT)
    cup_mounting_0110636: "0110636",
    // Ball Joint fitting (PMAT)
    cup_mounting_0110635: "0110635",
    // FSCM profile 200 18mm fem3/8
    cup_mounting_fscm_profile_200: "FSCMP18200G38FG38M",
    // FSCM profile 140 18mm fem3/8
    cup_mounting_fscm_profile_140: "FSCMP18140G38FG38M",
    // FSCM profile 110 18mm fem3/8
    cup_mounting_fscm_profile_110: "FSCMP18110G38FG38M",
    // FSCM profile 80 18mm fem3/8
    cup_mounting_fscm_profile_80: "FSCMP1880G38FG38M",
    // FSCM profile 45 18mm fem3/8
    cup_mounting_fscm_profile_45: "FSCMP1845G38FG38M",
    // FSCM Ball Joint 200 18mm fem3/8
    cup_mounting_fscm_ball_joint_200: "FSCMI18200G38FG38M",
    // FSCM Ball Joint 140 18mm fem3/8
    cup_mounting_fscm_ball_joint_140: "FSCMI18140G38FG38M",
    // FSCM Ball Joint 110 18mm fem3/8
    cup_mounting_fscm_ball_joint_110: "FSCMI18110G38FG38M",
    // FSCM Ball Joint 80 18mm fem3/8
    cup_mounting_fscm_ball_joint_80: "FSCMI1880G38FG38M",
    // FSCM Ball Joint 45 18mm fem3/8
    cup_mounting_fscm_ball_joint_45: "FSCMI1845G38FG38M",
    // Inline vacuum filter 50um (piSAFE version)
    cup_mounting_0232709: "0232709",
    // Extension kit G3/8" 100mm
    cup_mounting_0223743: "0223743",
    // Extension kit G3/8" 200mm
    cup_mounting_0223744: "0223744",
};
export var fittingPlaceholderCupMounting = "Placeholder-Attachment";
export var cupMountingPlaceholderTube = "Placeholder-Attachment";
/* TUBE CONNECTION */
export var toolChangerDefaultLength = 0.05; //50mm
export var defaultTubeConnectionType = TubeConnectionId.FOUR_WAY_X;
export var tubeConnectionDefaultDiameter = 0.006;
export var tubeConnectionDefaultDiameterRange = [
    0.004, 0.016,
];
export var tubeConnectionMaterialAreaName = "MA_Tubing";
export var tubeConnectionModels = (_c = {},
    _c[TubeConnectionId.THREE_WAY_T] = {
        id: TubeConnectionId.THREE_WAY_T,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.three_way_t",
        modelName: "Coupling-3T",
        placeholderNames: [
            "Placeholder-TubeBack",
            "Placeholder-TubeLeft",
            "Placeholder-TubeRight",
        ],
        isDouble: false,
        diameterRange: tubeConnectionDefaultDiameterRange,
    },
    _c[TubeConnectionId.THREE_WAY_Y] = {
        id: TubeConnectionId.THREE_WAY_Y,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.three_way_y",
        modelName: "Coupling-3Y",
        placeholderNames: [
            "Placeholder-TubeBack",
            "Placeholder-TubeLeft",
            "Placeholder-TubeRight",
        ],
        isDouble: false,
        diameterRange: tubeConnectionDefaultDiameterRange,
    },
    _c[TubeConnectionId.TWO_WAY_90] = {
        id: TubeConnectionId.TWO_WAY_90,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.two_way_90",
        modelName: "Coupling-2turn90",
        placeholderNames: ["Placeholder-TubeBack", "Placeholder-TubeLeft"],
        isDouble: false,
        diameterRange: tubeConnectionDefaultDiameterRange,
    },
    _c[TubeConnectionId.FOUR_WAY_X] = {
        id: TubeConnectionId.FOUR_WAY_X,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.four_way_x",
        modelName: "Coupling-4X",
        placeholderNames: [
            "Placeholder-TubeBack",
            "Placeholder-TubeFront",
            "Placeholder-TubeLeft",
            "Placeholder-TubeRight",
        ],
        isDouble: false,
        diameterRange: tubeConnectionDefaultDiameterRange,
    },
    _c[TubeConnectionId.FOUR_WAY_TT] = {
        id: TubeConnectionId.FOUR_WAY_TT,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.four_way_tt",
        modelName: "Coupling-4TT",
        placeholderNames: [
            "Placeholder-TubeBack",
            "Placeholder-TubeFront",
            "Placeholder-TubeLeftBack",
            "Placeholder-TubeLeftFront",
        ],
        isDouble: true,
        diameterRange: tubeConnectionDefaultDiameterRange,
    },
    _c[TubeConnectionId.FOUR_WAY_TT_ALTERNATE] = {
        id: TubeConnectionId.FOUR_WAY_TT,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.four_way_tt_alternate",
        modelName: "Coupling-4TTalternate",
        placeholderNames: [
            "Placeholder-TubeBack",
            "Placeholder-TubeFront",
            "Placeholder-TubeLeft",
            "Placeholder-TubeRight",
        ],
        isDouble: true,
        diameterRange: tubeConnectionDefaultDiameterRange,
    },
    _c[TubeConnectionId.FOUR_WAY_TT_ALTERNATE_MIRROR] = {
        id: TubeConnectionId.FOUR_WAY_TT_ALTERNATE_MIRROR,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.four_way_tt_alternate_mirror",
        modelName: "Coupling-4TTalternateMirrored",
        placeholderNames: [
            "Placeholder-TubeBack",
            "Placeholder-TubeFront",
            "Placeholder-TubeLeft",
            "Placeholder-TubeRight",
        ],
        isDouble: true,
        diameterRange: tubeConnectionDefaultDiameterRange,
    },
    _c[TubeConnectionId.TOOL_CHANGER] = {
        id: TubeConnectionId.TOOL_CHANGER,
        translationKey: "sidebar.edit_tube_connection.connection_point_types.tool_changer",
        modelName: "Toolchanger1-1",
        placeholderNames: ["Placeholder-TubeIn01", "Placeholder-TubeOut01"],
        isDouble: false,
        diameterRange: [0.002, 0.02],
    },
    _c);
/* FEED PRESSURE POINT*/
export var feedPressurePointModelName = "Regulator";
export var feedPressurePointPlaceholderName = "Placeholder-TubeRight";
export var feedPressurePointEntityName = "FeedPressurePoint";
export var feedPressurePointDefaultX = -0.3;
export var feedPressurePointDefaultZ = -0.6;
export var feedPressurePointNodeId = "feed-pressure-point";
/* TUBE */
export var tubeDefaultLength = 0.5;
export var tubeCylinderAsset = "Primitive-Cylinder10x10cm";
export var tubeMaterials = (_d = {},
    _d[TubeType.VACUUM] = [Material.PLASTIC_GLOSSY_BLUE],
    _d[TubeType.COMPRESSED_AIR] = [
        Material.PLASTIC_GLOSSY_RED,
        Material.PLASTIC_GLOSSY_YELLOW,
    ],
    _d[TubeType.UNKNOWN] = [Material.PLASTIC_MATTE_GREY],
    _d);
export var tubeMaterialsLengthLocked = (_e = {},
    _e[TubeType.VACUUM] = [Material.PLASTIC_GLOSSY_DARK_BLUE],
    _e[TubeType.COMPRESSED_AIR] = [
        Material.PLASTIC_GLOSSY_DARK_RED,
        Material.PLASTIC_GLOSSY_DARK_YELLOW,
    ],
    _e[TubeType.UNKNOWN] = [Material.PLASTIC_GLOSSY_BLACK],
    _e);
/* ATTACHMENT */
export var attachmentModel = "Arrow";
export var attachmentMaterialArea = "MA_Arrow";
export var attachmentMaterials = (_f = {},
    _f[TubeType.VACUUM] = {
        standard: Material.PLASTIC_MATTE_BLUE,
        transparent: Material.TRANSPARENT_BLUE,
    },
    _f[TubeType.COMPRESSED_AIR] = {
        standard: Material.PLASTIC_MATTE_RED,
        transparent: Material.TRANSPARENT_RED,
    },
    _f[TubeType.UNKNOWN] = {
        standard: Material.PLASTIC_MATTE_GREY,
        transparent: Material.TRANSPARENT_GREY,
    },
    _f);
/* VACUUM PUMP */
export var centralizedVacuumPumpDefaultX = 0;
export var centralizedVacuumPumpDefaultZ = -0.5;
export var defaultCentralPumpModel = {
    modelName: "PCS",
    placeholderBack: "Placeholder-TubeBack",
    placeholderFront: "Placeholder-TubeFront",
};
export var centralizedVacuumPumps = {
    PC23S_1: {
        modelName: "PCS",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    PC23S_2: {
        modelName: "PC23S-2",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    PC10X_1: {
        modelName: "PC10X-1",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    PC10X_2: {
        modelName: "PC10X-2",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    ACP: {
        modelName: "ACP",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    "PP10X.MC1": {
        modelName: "PP10X-MC1",
        placeholderBack: "Placeholder-TubeBack1",
        placeholderFront: "Placeholder-TubeFront",
    },
    "PP10X.MC2": {
        modelName: "PP10X-MC2",
        placeholderBack: "Placeholder-TubeBack1",
        placeholderFront: "Placeholder-TubeFront",
    },
    "PP23.X": {
        modelName: "PP23-X",
        placeholderBack: "Placeholder-TubeBack1",
        placeholderFront: "Placeholder-TubeFront",
    },
    "PP23.Z": {
        modelName: "PP23-Z",
        placeholderBack: "Placeholder-TubeBack1",
        placeholderFront: "Placeholder-TubeFront",
    },
    P3010x1: {
        modelName: "P3010x1",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    P3010x2: {
        modelName: "P3010x2",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    "P3010x1-QR": {
        modelName: "P3010x1-QR",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    "P3010x2-QR": {
        modelName: "P3010x2-QR",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    "piClassic_1-2": {
        modelName: "piClassic-1-2",
        placeholderBack: "Placeholder-TubeFront3",
        placeholderFront: "Placeholder-TubeFront",
    },
    "piClassic_3-4": {
        modelName: "piClassic-3-4",
        placeholderBack: "Placeholder-TubeFront3",
        placeholderFront: "Placeholder-TubeFront",
    },
    "piClassic_5-6": {
        modelName: "piClassic-5-6",
        placeholderBack: "Placeholder-TubeFront3",
        placeholderFront: "Placeholder-TubeFront",
    },
    vacuplus_midi_x1: {
        modelName: "Vacuplus-Midi-x1",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
    vacuplus_midi_x2: {
        modelName: "Vacuplus-Midi-x2",
        placeholderBack: "Placeholder-TubeTop",
        placeholderFront: "Placeholder-TubeFront",
    },
    "0102797": {
        modelName: "0102797",
        placeholderBack: "Placeholder-TubeRight",
        placeholderFront: "Placeholder-TubeFront",
    },
    Vacuplus_mini: {
        modelName: "Vacuplus-Mini",
        placeholderBack: "Placeholder-TubeRight",
        placeholderFront: "Placeholder-TubeFront",
    },
    single_stage_central_A: {
        modelName: "GenericPump-Centralized01",
        placeholderBack: "Placeholder-TubeBack",
        placeholderFront: "Placeholder-TubeFront",
    },
};
export var defaultDecentralPumpModel = {
    modelName: "PSF",
    placeholderTube: "Placeholder-TubeBack",
};
export var decentralizedVacuumPumps = {
    PISAFE: {
        modelName: "PSF",
        placeholderTube: "Placeholder-TubeBack",
    },
    PIL_AQR: {
        modelName: "PIL-AQR",
        placeholderTube: "Placeholder-TubeBack",
    },
    PIL_EBR: {
        modelName: "PIL-EBR",
        placeholderTube: "Placeholder-TubeBack1",
    },
    PISTAMP: {
        modelName: "piSTAMP",
        placeholderTube: "Placeholder-TubeBack1",
    },
    "piInline Micro": {
        modelName: "piInline-Micro",
        placeholderTube: "Placeholder-TubeBack",
    },
    "piInline Mini": {
        modelName: "piInline-Mini",
        placeholderTube: "Placeholder-TubeBack",
    },
    VGS2010: {
        modelName: "VGS2010",
        placeholderTube: "Placeholder-TubeBack",
    },
    VGS3010: {
        modelName: "VGS3010",
        placeholderTube: "Placeholder-TubeBack",
    },
    "VGS3010-2": {
        modelName: "VGS3010-2",
        placeholderTube: "Placeholder-TubeBack",
    },
    VGS5010: {
        modelName: "VGS5010",
        placeholderTube: "Placeholder-TubeBack",
    },
    VGS4005: {
        modelName: "VGS4005",
        placeholderTube: "Placeholder-TubeBack",
    },
    single_stage_decentral_A: {
        modelName: "GenericPump-Decentralized01",
        placeholderTube: "Placeholder-TubeBack2",
    },
    single_stage_decentral_05: {
        modelName: "GenericPump-DecentralizedInline05",
        placeholderTube: "Placeholder-TubeBack",
    },
    single_stage_decentral_095: {
        modelName: "GenericPump-DecentralizedInline095",
        placeholderTube: "Placeholder-TubeBack",
    },
    "0222882": {
        modelName: "piInline-Micro",
        placeholderTube: "Placeholder-TubeBack",
    },
    "0122896": {
        modelName: "piInline-Mini",
        placeholderTube: "Placeholder-TubeBack",
    },
    "piInline Midi": {
        modelName: "piInline-Midi",
        placeholderTube: "Placeholder-TubeBack",
    },
    "0122899": {
        modelName: "piInline-Midi",
        placeholderTube: "Placeholder-TubeBack",
    },
};
export var defaultInlineTubeLength = 0.1;
export var defaultInlineTubeDiameter = 0.004;
/* OBJECT */
export var defaultObject = MetalObjectShapeId.ADJUSTABLE_METAL_BOX;
export var objectShapes = __assign(__assign(__assign(__assign({}, metalObjectShapes), glassObjectShapes), plasticObjectShapes), cardboardObjectShapes);
export var objectPosition = {
    x: 0,
    y: 5,
    z: 0,
};
/* Other camera views */
export var screenshotCameraView = {
    x: 0,
    y: 3.8,
    z: 0,
    rotx: 0,
    roty: 90,
    distance: 3.5,
};
