var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import * as pc from "playcanvas";
import { ovalGripPositionDiameterRatio } from "./constants/common";
import { GripPositionMarkerId, } from "store/types";
var distanceToFirstCurve = 0.05;
var stepSize = 0.02;
export var almostEqual = function (a, b, threshold) {
    if (threshold === void 0) { threshold = 0.0001; }
    return Math.abs(a - b) < threshold;
};
/* Volume of a cylinder: h*π*r^2, multiply by 1000 for dm3
 * Assuming height & diameter are in m  */
export var getCylinderVolumeDm3 = function (height, diameter) {
    return Math.PI * Math.pow(diameter / 2, 2) * height * 1000;
};
export var trimModelName = function (name) {
    return name.replace(/(\.model)?(\.json)?(\.glb)?$/i, "");
};
var getToAndFromEntities = function (props) {
    var _a;
    var app = props.app, fromNode = props.fromNode, fromAttachment = props.fromAttachment, toNode = props.toNode, toAttachment = props.toAttachment;
    var fromNodeEntity = app.root.findByName(fromNode.id.toString());
    var fromPlaceholderEntity = fromAttachment.placeholderEntityName
        ? fromNodeEntity === null || fromNodeEntity === void 0 ? void 0 : fromNodeEntity.findByName(fromAttachment.placeholderEntityName)
        : fromNodeEntity;
    var toNodeEntity = app.root.findByName((_a = toAttachment.placeholderEntityName) !== null && _a !== void 0 ? _a : toNode.id.toString());
    var toPlaceholderEntity = toAttachment.placeholderEntityName
        ? toNodeEntity === null || toNodeEntity === void 0 ? void 0 : toNodeEntity.findByName(toAttachment.placeholderEntityName)
        : toNodeEntity;
    var fromPlaceholder;
    var toPlaceholder;
    if (fromPlaceholderEntity && toPlaceholderEntity) {
        fromPlaceholder = fromPlaceholderEntity.findByName(fromAttachment.placeholderName);
        toPlaceholder = toPlaceholderEntity.findByName(toAttachment.placeholderName);
    }
    return { fromEntity: fromPlaceholder, toEntity: toPlaceholder };
};
export var connectNodes = function (props) {
    var _a = getToAndFromEntities(props), fromEntity = _a.fromEntity, toEntity = _a.toEntity;
    if (!fromEntity || !toEntity)
        return 0;
    var cylinderAsset = props.cylinderAsset, material = props.material, rootEntity = props.rootEntity, diameter = props.diameter, offsetX = props.offsetX;
    var fromDirection = fromEntity.forward.mulScalar(-1);
    var toDirection = toEntity.forward.mulScalar(-1);
    var points = [
        fromEntity.getPosition().clone(),
        fromEntity
            .getPosition()
            .clone()
            .add(fromDirection.mulScalar(distanceToFirstCurve)),
        toEntity
            .getPosition()
            .clone()
            .add(toDirection.mulScalar(distanceToFirstCurve)),
        toEntity.getPosition().clone(),
    ];
    return drawCurve(points, stepSize, 0.1, diameter, rootEntity, cylinderAsset, material, offsetX);
};
export var connectNodesWithLockedEdgeLength = function (props) {
    var _a = getToAndFromEntities(props), fromEntity = _a.fromEntity, toEntity = _a.toEntity;
    if (!fromEntity || !toEntity)
        return 0;
    var cylinderAsset = props.cylinderAsset, material = props.material, rootEntity = props.rootEntity, diameter = props.diameter, offsetX = props.offsetX, lengthLockedMaterial = props.lengthLockedMaterial;
    var fromVector = fromEntity.getPosition().clone();
    var toVector = toEntity.getPosition().clone();
    var fromDirection = fromEntity.forward.mulScalar(-1);
    var toDirection = toEntity.forward.mulScalar(-1);
    /**
     *   	toEntity
     * 		 	o
     * 		 	|
     * 		 	|
     * 	 o(a2)	|    o(b3)
     * 	 	 	o (b1)
     * 	  	  ^	|
     *   (r1) | |     We need to generate two opposing middle points (a1)&(b1) in order to create the Z curve.
     * 		  | |
     * 		 	o (a1)
     * 	 o(a3)	|    o(b2)
     * 		 	|
     *   	 	|
     * 		 	o
     * 	 	fromEntity
     */
    var vecBetweenFromAndTo = new pc.Vec3().sub2(toVector, fromVector);
    // These two vectors are positioned slightly before and after the middle position between the two entities.
    //  This generates vector starting at fromEntity poiting at(a1)
    var vecPointingAtA1 = new pc.Vec3().add2(fromVector, vecBetweenFromAndTo.clone().mulScalar(0.46));
    // This generates vector starting at fromEntity poiting at (b1)
    var vecPointingAtB1 = new pc.Vec3().add2(fromVector, vecBetweenFromAndTo.clone().mulScalar(0.54));
    // This vector starts at (a1) and points to (b1). This is named (r1).
    var vecBetweenMiddleVectors = new pc.Vec3().sub2(vecPointingAtB1, vecPointingAtA1);
    // We want to generate (a2), so we need to setup a rotation matrix to be able to rotate (r1) to point at (a2)
    var positiveRotationMatrix = new pc.Mat4().setFromEulerAngles(0, 30, 0);
    var positiveR1RotatedTowardsA2 = positiveRotationMatrix.transformVector(vecBetweenMiddleVectors.clone());
    // This generates the vector pointing at (a2). We scale it by 1.3 so that it reaches behind (b1)
    var vecPointingAtA2 = new pc.Vec3().add2(vecPointingAtA1, positiveR1RotatedTowardsA2.clone().mulScalar(1.3));
    // We want to generate (b2), so we need to setup a rotation matrix to be able to rotate (r1) to point at (b2)
    var negativeRotationMatrix = new pc.Mat4().setFromEulerAngles(0, 30, 0);
    // Since (r1) is pointing from (a1) to (b1) we need to scale it by -1 to flip its direction.
    var negativeR1RotatedTowardsB2 = negativeRotationMatrix.transformVector(vecBetweenMiddleVectors.clone().mulScalar(-1));
    // This generates the vector pointing at (b2). We scale it by 1.3 so that it reaches behind (a1)
    var vecPointingAtB2 = new pc.Vec3().add2(vecPointingAtB1, negativeR1RotatedTowardsB2.clone().mulScalar(1.3));
    /* In order to create a smooth curve from the first segment and the last segment of the tube,
    we need opposing control points at both (a1) and (b1), these control points are (a3) and (b3) */
    var vecFromB1ToB2 = new pc.Vec3().sub2(vecPointingAtB2, vecPointingAtB1);
    var controlPointB3 = new pc.Vec3().add2(vecPointingAtB1, vecFromB1ToB2.clone().mulScalar(-1));
    var vecFromA1ToA2 = new pc.Vec3().sub2(vecPointingAtA2, vecPointingAtA1);
    var controlPointA3 = new pc.Vec3().add2(vecPointingAtA1, vecFromA1ToA2.clone().mulScalar(-1));
    var drawCurveFromPoints = function (points, material) {
        drawCurve(points, stepSize, 0.1, diameter, rootEntity, cylinderAsset, material, offsetX);
    };
    // Generate 4 bezier control points for the first segment of the tube from the fromEntity to (a1);
    var pointsInFirstSegment = [
        fromEntity.getPosition().clone(),
        fromEntity
            .getPosition()
            .clone()
            .add(fromDirection.mulScalar(distanceToFirstCurve)),
        controlPointA3,
        vecPointingAtA1.clone(),
    ];
    drawCurveFromPoints(pointsInFirstSegment, material);
    // Generate 4 bezier control points for the curved middle segment of the tube from (a1) to (b1);
    var pointsInCurvedSegment = [
        vecPointingAtA1.clone(),
        vecPointingAtA2.clone(),
        vecPointingAtB2.clone(),
        vecPointingAtB1.clone(),
    ];
    drawCurveFromPoints(pointsInCurvedSegment, lengthLockedMaterial);
    // Generate 4 bezier control points for the last segment (b1) to toEntity;
    var pointsInLastSegment = [
        vecPointingAtB1.clone(),
        controlPointB3.clone(),
        toEntity
            .getPosition()
            .clone()
            .add(toDirection.mulScalar(distanceToFirstCurve)),
        toEntity.getPosition().clone(),
    ];
    drawCurveFromPoints(pointsInLastSegment, material);
    // This return statement of this method is only used when length isn't locked, so no need to return a calculated length of the tube.
    return 0;
};
/**
 * Draw a quadratic bezier curve through three points using cylinders.
 * StepSize determines how many segments the curve is divided into and thus
 * how may cylinders we render. Return the length of the curve.
 *
 * Resource on Bezier curves: https://pomax.github.io/bezierinfo/
 * */
export var drawCurve = function (points, stepSize, assetScale, radius, rootEntity, cylinderModelAsset, tubeMaterial, offsetX) {
    if (offsetX === void 0) { offsetX = 0; }
    if (points.length !== 3 && points.length !== 4)
        return 0;
    var t = 0;
    var bezierPositions = [];
    while (t <= 1) {
        var position = points.length === 3 ? quadraticBezier(t, points) : cubicBezier(t, points);
        bezierPositions.push(position);
        t += stepSize;
    }
    bezierPositions.push(points[points.length - 1]);
    var curveLength = 0;
    bezierPositions.forEach(function (position, ix) {
        if (ix === 0)
            return;
        var segmentEntity = new pc.Entity();
        segmentEntity.addComponent("model", { type: "asset" });
        rootEntity.addChild(segmentEntity);
        var segmentLength = drawCylinder(bezierPositions[ix - 1], position, assetScale, radius, segmentEntity, cylinderModelAsset, tubeMaterial, offsetX);
        curveLength += segmentLength;
    });
    var endpointEntity = new pc.Entity("Sphere");
    rootEntity.addChild(endpointEntity);
    endpointEntity.setLocalScale(radius, radius, radius);
    endpointEntity.setPosition(bezierPositions[bezierPositions.length - 1]);
    if (endpointEntity.model) {
        endpointEntity.model.meshInstances[0].material = tubeMaterial;
    }
    return curveLength;
};
export var drawCylinder = function (from, to, assetScale, radius, entity, modelAsset, material, offsetX) {
    if (offsetX === void 0) { offsetX = 0; }
    if (!entity.model)
        return 0;
    entity.model.asset = modelAsset;
    entity.model.meshInstances[0].material = material;
    var cylinderLength = Math.sqrt(Math.pow(to.x - from.x, 2) +
        Math.pow(to.y - from.y, 2) +
        Math.pow(to.z - from.z, 2)) + 0.001;
    entity.setLocalScale(radius / assetScale, cylinderLength / assetScale, radius / assetScale);
    entity.setPosition(from);
    entity.lookAt(to);
    var angles = entity.getLocalEulerAngles().clone();
    angles.x -= 90;
    entity.setLocalEulerAngles(angles);
    var offset = entity.right.clone().mulScalar(offsetX);
    entity.setPosition(entity.getPosition().clone().add(offset));
    return cylinderLength;
};
export var quadraticBezier = function (t, w) {
    var t2 = t * t;
    var mt = 1 - t;
    var mt2 = mt * mt;
    var firstTerm = w[0].clone().mulScalar(mt2);
    var secondTerm = w[1].clone().mulScalar(2 * mt * t);
    var thirdTerm = w[2].clone().mulScalar(t2);
    return firstTerm.add(secondTerm).add(thirdTerm);
};
export var cubicBezier = function (t, w) {
    var t2 = t * t;
    var t3 = t2 * t;
    var mt = 1 - t;
    var mt2 = mt * mt;
    var mt3 = mt2 * mt;
    var firstTerm = w[0].clone().mulScalar(mt3);
    var secondTerm = w[1].clone().mulScalar(3 * mt2 * t);
    var thirdTerm = w[2].clone().mulScalar(3 * mt * t2);
    var fourthTerm = w[3].clone().mulScalar(t3);
    return firstTerm.add(secondTerm).add(thirdTerm).add(fourthTerm);
};
export var checkGripPositionFit = function (gripPosition, width, height) {
    if (gripPosition.marker === GripPositionMarkerId.CIRCLE) {
        return width <= gripPosition.width && height <= gripPosition.width;
    }
    else {
        var ovalShortDiameter = gripPosition.width / ovalGripPositionDiameterRatio;
        var ovalLongDiameter = gripPosition.width;
        return ((width <= ovalLongDiameter && height <= ovalShortDiameter) ||
            (height <= ovalLongDiameter && width <= ovalShortDiameter));
    }
};
export var checkGripPositionsFit = function (gripPositions, width, height) {
    return gripPositions.every(function (gripPosition) {
        return checkGripPositionFit(gripPosition, width, height);
    });
};
// The suctionCupCompatibility and mountingCompatibility fields from tacton is a string, it exists on all cups and all mountings.
// That string needs to be the same for cup and mounting for them to be compatible.
// We only use this to filter mountings, cups won't be filtered. Instead we display a warning to the user if a newly selected cup no longer is compatible -
// with the currently selected mounting. The user is then prompted to change mounting.
export var ALWAYS_COMPATIBLE = "Always Compatible";
export var isMountingCompatibleWithSuctionCup = function (suctionCup, mounting) {
    if (!suctionCup || !mounting)
        return true;
    var mountingCompatibility = suctionCup.mountingCompatibility;
    var suctionCupCompatibility = mounting.suctionCupCompatibility;
    // The "none selection" of a cup or mounting is always compatibile with all.
    var isAlwaysCompatible = [
        suctionCupCompatibility,
        mountingCompatibility,
    ].some(function (compatibility) { return compatibility === ALWAYS_COMPATIBLE; });
    if (isAlwaysCompatible)
        return true;
    var isCompatible = suctionCupCompatibility === mountingCompatibility;
    return isCompatible;
};
var recursivelySetMaterial = function (entity, material) {
    var e_1, _a;
    var _b;
    var meshes = (_b = entity.model) === null || _b === void 0 ? void 0 : _b.meshInstances;
    if (meshes && meshes.length > 0) {
        meshes.forEach(function (meshInstance) {
            meshInstance.material = material(meshInstance);
        });
    }
    try {
        for (var _c = __values(entity.children), _d = _c.next(); !_d.done; _d = _c.next()) {
            var child = _d.value;
            recursivelySetMaterial(child, material);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
var collectMaterials = function (entity, materials) {
    var e_2, _a;
    var _b;
    (_b = entity.model) === null || _b === void 0 ? void 0 : _b.meshInstances.map(function (mesh) { return mesh.material; }).forEach(function (material) { return materials.add(material); });
    try {
        for (var _c = __values(entity.children), _d = _c.next(); !_d.done; _d = _c.next()) {
            var child = _d.value;
            collectMaterials(child, materials);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_2) throw e_2.error; }
    }
};
var emphasizeMaterial = function (material) {
    var emphasized = material.clone();
    emphasized.diffuse.r *= 0.5;
    emphasized.diffuse.g *= 0.5;
    emphasized.diffuse.b *= 0.5;
    emphasized.update();
    return emphasized;
};
export var emphasizeEntity = function (entity) {
    var materialSet = new Set();
    collectMaterials(entity, materialSet);
    var materials = Array.from(materialSet);
    // creating one emphasized version of each material, to reuse for the entity
    var emphasizedMaterials = materials.map(emphasizeMaterial);
    var material = function (meshInstance) {
        var index = materials.findIndex(function (material) { return material === meshInstance.material; });
        return emphasizedMaterials[index];
    };
    recursivelySetMaterial(entity, material);
};
var fadeMaterial = function (material) {
    var faded = material.clone();
    faded.opacity = 0.2 * faded.opacity;
    faded.blendType = pc.BLEND_NORMAL;
    faded.update();
    return faded;
};
export var fadeEntity = function (entity) {
    var materialSet = new Set();
    collectMaterials(entity, materialSet);
    var materials = Array.from(materialSet);
    // creating one faded version of each material, to reuse for the entity
    var fadedMaterials = materials.map(fadeMaterial);
    var material = function (meshInstance) {
        var index = materials.findIndex(function (material) { return material === meshInstance.material; });
        return fadedMaterials[index];
    };
    recursivelySetMaterial(entity, material);
};
export var resetMaterial = function (entity, materialAssets) {
    var e_3, _a;
    if (entity.model && entity.model.meshInstances) {
        entity.model.meshInstances.forEach(function (meshInstance) {
            var material = materialAssets[meshInstance.material.name]
                .resource;
            meshInstance.material = material;
        });
    }
    try {
        for (var _b = __values(entity.children), _c = _b.next(); !_c.done; _c = _b.next()) {
            var child = _c.value;
            resetMaterial(child, materialAssets);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
};
export var getBoundingBox = function (entity) {
    var bbox = new pc.BoundingBox();
    var hasCopiedBBox = false;
    var recursiveFillBBox = function (entity) {
        if (entity.model && entity.model.meshInstances && entity.enabled) {
            entity.model.meshInstances.forEach(function (meshInstance) {
                if (!hasCopiedBBox) {
                    bbox.copy(meshInstance.aabb);
                    hasCopiedBBox = true;
                }
                else {
                    bbox.add(meshInstance.aabb);
                }
            });
        }
        entity.children.forEach(function (child) { return recursiveFillBBox(child); });
    };
    recursiveFillBBox(entity);
    return hasCopiedBBox ? bbox : undefined;
};
export var getMaterialAssets = function (materialAssets, materialTransforms) {
    if (!materialTransforms)
        return;
    return materialTransforms.reduce(function (acc, transform) {
        acc[transform.sourceMaterial] = materialAssets[transform.targetMaterial];
        return acc;
    }, {});
};
export var getMaterialTransformsForObjectShape = function (objectShape, useTexturedSurfaces) {
    var objectHasTexturedMaterials = objectShape.texturedMaterialTransforms !== undefined;
    if (objectHasTexturedMaterials && useTexturedSurfaces) {
        return objectShape.texturedMaterialTransforms;
    }
    return objectShape.materialTransforms;
};
export var applyMaterialTransforms = function (entity, transforms, uvScale) {
    if (!entity.model || !transforms)
        return;
    entity.model.meshInstances.forEach(function (meshInstance) {
        var materialTransformAsset = transforms === null || transforms === void 0 ? void 0 : transforms[meshInstance.material.name];
        if (materialTransformAsset) {
            var material = materialTransformAsset.resource.clone();
            if (uvScale) {
                material.diffuseMapTiling = uvScale;
                material.glossMapTiling = uvScale;
                material.normalMapTiling = uvScale;
                material.update();
            }
            meshInstance.material = material;
        }
    });
};
