var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Material } from "3d/types";
/** ---------------   **/
/**    BX-P Family    **/
/** ---------------   **/
var cupModelTemplate = {
    BX15P: {
        cupModelName: "BX15P",
        cupWidth: 0.016,
        cupHeight: 0.023,
        fittingModelName: "MM5M-S1",
    },
    BX35P: {
        cupModelName: "BX35P",
        cupWidth: 0.037,
        cupHeight: 0.0328,
        fittingModelName: "G38M01-S10",
    },
    BX75P: {
        cupModelName: "BX75P",
        cupWidth: 0.0775,
        cupHeight: 0.0517,
        fittingModelName: "G38M01-S8",
    },
};
var materialTransformTemplate = {
    PU60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU60,
            },
        ],
    },
    PU30_60: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60 },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU30,
            },
        ],
    },
    PU60_SEMI_CONDUCTIVE: {
        materialTransforms: [
            { sourceMaterial: Material.MA_MAIN, targetMaterial: Material.PU60SC },
            {
                sourceMaterial: Material.MA_MAIN2,
                targetMaterial: Material.PU60SC,
            },
        ],
    },
};
export var suctionCupsFamilyBX_P = {
    // BX10P PU60SC
    suction_cup_0245010: __assign(__assign(__assign({}, cupModelTemplate.BX15P), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "MM5M-S3" }),
    // BX10P PU60
    suction_cup_0122967: __assign(__assign({}, cupModelTemplate.BX15P), materialTransformTemplate.PU60),
    // BX10P PU30/60
    suction_cup_0122869: __assign(__assign({}, cupModelTemplate.BX15P), materialTransformTemplate.PU30_60),
    // BX15P PU60SC
    suction_cup_0245012: __assign(__assign(__assign({}, cupModelTemplate.BX15P), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "MM5M-S3" }),
    // BX15P PU60
    suction_cup_0124345: __assign(__assign({}, cupModelTemplate.BX15P), materialTransformTemplate.PU60),
    // BX15P PU30/60
    suction_cup_0124344: __assign(__assign({}, cupModelTemplate.BX15P), materialTransformTemplate.PU30_60),
    // BX20P PU60SC
    suction_cup_9981339: __assign(__assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "G38M01-S9" }),
    // BX20P PU60
    suction_cup_0125112: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60),
    // BX20P PU30/60
    suction_cup_0125108: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU30_60),
    // BX25P PU60SC
    suction_cup_9981379: __assign(__assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "G38M01-S9" }),
    // BX25P PU60
    suction_cup_0114149: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60),
    // BX25P PU30/60
    suction_cup_0109402: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU30_60),
    // BX35P PU60SC
    suction_cup_9981332: __assign(__assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60_SEMI_CONDUCTIVE), { fittingModelName: "G38M01-S9" }),
    // BX35P PU60
    suction_cup_0107379: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60),
    // BX35P PU30/60
    suction_cup_0107377: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU30_60),
    // BX45P PU60
    suction_cup_9982614: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60),
    // BX45P PU30/60
    suction_cup_9982612: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU30_60),
    // BX52P PU60
    suction_cup_0107387: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60),
    // BX52P PU30/60
    suction_cup_0106740: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU30_60),
    // BX65P PU60
    suction_cup_9980026: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU60),
    // BX65P PU30/60
    suction_cup_9980227: __assign(__assign({}, cupModelTemplate.BX35P), materialTransformTemplate.PU30_60),
    // BX75P PU60
    suction_cup_0107149: __assign(__assign({}, cupModelTemplate.BX75P), materialTransformTemplate.PU60),
    // BX75P PU30/60
    suction_cup_0107151: __assign(__assign({}, cupModelTemplate.BX75P), materialTransformTemplate.PU30_60),
    // BX90P PU60
    suction_cup_9982615: __assign(__assign({}, cupModelTemplate.BX75P), materialTransformTemplate.PU60),
    // BX90P PU30/60
    suction_cup_9980226: __assign(__assign({}, cupModelTemplate.BX75P), materialTransformTemplate.PU30_60),
    // BX110P PU60
    suction_cup_0108341: __assign(__assign({}, cupModelTemplate.BX75P), materialTransformTemplate.PU60),
    // BX110P PU30/60
    suction_cup_0108273: __assign(__assign({}, cupModelTemplate.BX75P), materialTransformTemplate.PU30_60),
};
