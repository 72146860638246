var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState, useEffect, useCallback, useMemo, } from "react";
import * as pc from "playcanvas";
import { useSelectionRectangleState, } from "./selection-rectangle-state";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { useIsKeyPressed } from "hooks/is-key-pressed";
export var EntityType;
(function (EntityType) {
    EntityType["ATTACHMENT"] = "attachment";
    EntityType["NODE"] = "node";
    EntityType["EDGE"] = "edge";
    EntityType["OTHER"] = "other";
})(EntityType || (EntityType = {}));
export var usePicker = function (app, canvasRef) {
    var _a = __read(useState(null), 2), picker = _a[0], setPicker = _a[1];
    var _b = __read(useState(null), 2), picked = _b[0], setPicked = _b[1];
    var _c = __read(useState(false), 2), isEntityMoving = _c[0], setIsEntityMoving = _c[1];
    var _d = __read(useState(undefined), 2), hoveredId = _d[0], setHoveredId = _d[1];
    var _e = __read(useState(null), 2), initialY = _e[0], setInitialY = _e[1];
    var _f = __read(useState(false), 2), isDisabled = _f[0], setIsDisabled = _f[1];
    var nodes = useAppSelector(selectors.selectNodes);
    var edges = useAppSelector(selectors.selectEdges);
    var attachments = useAppSelector(selectors.selectAttachments);
    var currentlySelectedId = useAppSelector(selectors.selectSelectedId);
    var currentlySelectedIds = useAppSelector(selectors.selectSelectedIds);
    var isMultiSelecting = useAppSelector(selectors.selectIsMultiSelecting);
    var dispatch = useAppDispatch();
    var _g = useSelectionRectangleState(), selectionRectangle = _g.selectionRectangle, startSelection = _g.startSelection, updateSelection = _g.updateSelection, stopSelection = _g.stopSelection;
    var ctrlKeyPressed = useIsKeyPressed("Control");
    var metaKeyPressed = useIsKeyPressed("Meta"); // command key on mac
    var multiSelectKeyPressed = ctrlKeyPressed || metaKeyPressed;
    /**
     * Returns the picked entity based on the given mesh instance.
     */
    var getPickedEntity = useCallback(function (meshInstance) {
        var entity = meshInstance.node.parent;
        var id = entity.name;
        while (!nodes[id] && !attachments[id] && !edges[id] && entity.parent) {
            entity = entity.parent;
            id = entity.name;
        }
        var type = nodes[id]
            ? EntityType.NODE
            : attachments[id]
                ? EntityType.ATTACHMENT
                : edges[id]
                    ? EntityType.EDGE
                    : EntityType.OTHER;
        return { entity: entity, id: id, type: type };
    }, [attachments, edges, nodes]);
    /**
     * Picks unique entities in the scene based on the given coordinates.
     * Supports both single and multi-select, depending on if width and height are provided.
     * If width and height are provided, the function will pick entities within the given area.
     */
    var pick = useCallback(function (_a, camera) {
        var e_1, _b;
        var x = _a.x, y = _a.y, width = _a.width, height = _a.height;
        if (!picker || !app)
            return;
        preparePicker(picker, app, camera);
        var selected = picker.getSelection(x, y, width, height);
        var uniquePickedEntities = new Map();
        try {
            for (var selected_1 = __values(selected), selected_1_1 = selected_1.next(); !selected_1_1.done; selected_1_1 = selected_1.next()) {
                var meshInstance = selected_1_1.value;
                var picked_1 = getPickedEntity(meshInstance);
                uniquePickedEntities.set(picked_1.id, picked_1);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (selected_1_1 && !selected_1_1.done && (_b = selected_1.return)) _b.call(selected_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return Array.from(uniquePickedEntities.values());
    }, [picker, app, getPickedEntity]);
    var setPickerDisabled = useCallback(function (disabled) {
        setIsDisabled(disabled);
        setHoveredId(undefined);
    }, []);
    var toggleEntitySelection = useCallback(function (toggledId) {
        if (currentlySelectedIds.includes(toggledId)) {
            dispatch(actions.selectEntities(currentlySelectedIds.filter(function (id) { return id !== toggledId; })));
            return;
        }
        dispatch(actions.selectEntities(__spreadArray(__spreadArray([], __read(currentlySelectedIds), false), [toggledId], false)));
    }, [currentlySelectedIds, dispatch]);
    var updatePickedAndSelectedPositions = useCallback(function (picked) {
        var e_2, _a;
        var _b;
        if (picked.type === EntityType.NODE) {
            var pickedPosition = picked.entity.getPosition();
            dispatch(actions.setNodePosition({
                position: {
                    x: pickedPosition.x,
                    y: pickedPosition.y,
                    z: pickedPosition.z,
                },
                nodeId: picked.entity.name,
            }));
        }
        try {
            for (var currentlySelectedIds_1 = __values(currentlySelectedIds), currentlySelectedIds_1_1 = currentlySelectedIds_1.next(); !currentlySelectedIds_1_1.done; currentlySelectedIds_1_1 = currentlySelectedIds_1.next()) {
                var id = currentlySelectedIds_1_1.value;
                if (id === picked.id)
                    continue;
                if (!nodes[id])
                    continue;
                var position = (_b = app === null || app === void 0 ? void 0 : app.root.findByName(id)) === null || _b === void 0 ? void 0 : _b.getPosition();
                if (!position)
                    continue;
                dispatch(actions.setNodePosition({
                    position: {
                        x: position.x,
                        y: position.y,
                        z: position.z,
                    },
                    nodeId: id,
                }));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (currentlySelectedIds_1_1 && !currentlySelectedIds_1_1.done && (_a = currentlySelectedIds_1.return)) _a.call(currentlySelectedIds_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }, [app === null || app === void 0 ? void 0 : app.root, currentlySelectedIds, dispatch, nodes]);
    var onMouseDown = useCallback(function (event, camera) {
        var _a;
        if (!app || !picker)
            return;
        // Prevent default event to avoid highlighting text when moving object
        event.event.preventDefault();
        // Focus the canvas to trigger blur events for input fields
        (_a = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        var pickedInfo = pick({ x: event.x, y: event.y }, camera);
        if (!(pickedInfo === null || pickedInfo === void 0 ? void 0 : pickedInfo.length))
            return;
        var _b = pickedInfo[0], entity = _b.entity, id = _b.id, type = _b.type;
        if (!multiSelectKeyPressed && !currentlySelectedIds.includes(id)) {
            dispatch(actions.deselectEntities());
        }
        switch (type) {
            case EntityType.ATTACHMENT: {
                // One attachment already selected -> Create tube
                if (currentlySelectedId && attachments[currentlySelectedId]) {
                    dispatch(actions.addEdge(currentlySelectedId, id));
                }
                else {
                    dispatch(actions.selectEntity(id));
                }
                break;
            }
            case EntityType.NODE: {
                var pickedPosition = entity.getPosition();
                setInitialY(pickedPosition.y);
                setPicked({ entity: entity, id: id, type: type });
                break;
            }
            case EntityType.EDGE: {
                setPicked({ entity: entity, id: id, type: type });
                break;
            }
            case EntityType.OTHER: {
                dispatch(actions.deselectEntities());
                startSelection(event);
                break;
            }
        }
    }, [
        app,
        picker,
        canvasRef,
        pick,
        multiSelectKeyPressed,
        currentlySelectedIds,
        dispatch,
        currentlySelectedId,
        attachments,
        startSelection,
    ]);
    var onMouseUp = useCallback(function (camera) {
        // User is dragging a selection rectangle
        if (selectionRectangle) {
            stopSelection();
            var _a = getPickerArea(selectionRectangle), x = _a.x, y = _a.y, width = _a.width, height = _a.height;
            // if the area is too small then it's not considered a selection
            if (width < 5 || height < 5)
                return;
            var pickedEntities = pick({ x: x, y: y, width: width, height: height }, camera);
            if (!pickedEntities)
                return;
            var isSelectable = function (_a) {
                var type = _a.type;
                return type !== EntityType.OTHER;
            };
            var selectedIds = pickedEntities
                .filter(isSelectable)
                .map(function (_a) {
                var id = _a.id;
                return id;
            });
            if (!selectedIds.length)
                return;
            dispatch(actions.selectEntities(selectedIds));
            return;
        }
        // User is picking nothing, nothing to do
        if (!picked)
            return;
        setPicked(null);
        if (isEntityMoving) {
            setIsEntityMoving(false);
            updatePickedAndSelectedPositions(picked);
            // if multi-selecting and moving nodes, early exit to avoid deselecting
            if (isMultiSelecting)
                return;
        }
        if (multiSelectKeyPressed) {
            // cannot multi-select when an attachment is currently selected. early exit
            if (attachments[currentlySelectedId])
                return;
            toggleEntitySelection(picked.id);
            return;
        }
        dispatch(actions.selectEntity(picked.id));
    }, [
        selectionRectangle,
        picked,
        isEntityMoving,
        multiSelectKeyPressed,
        dispatch,
        stopSelection,
        pick,
        updatePickedAndSelectedPositions,
        isMultiSelecting,
        attachments,
        currentlySelectedId,
        toggleEntitySelection,
    ]);
    var onMouseMove = useCallback(function (eventX, eventY, cameraEntity) {
        if (!app || !picker || !cameraEntity.camera)
            return;
        if (selectionRectangle) {
            updateSelection({ x: eventX, y: eventY });
            return;
        }
        if (!picked) {
            var pickedInfo = pick({ x: eventX, y: eventY }, cameraEntity.camera);
            if (!(pickedInfo === null || pickedInfo === void 0 ? void 0 : pickedInfo.length))
                return;
            var _a = pickedInfo[0], id = _a.id, type = _a.type;
            var isSelectedNodeHovered = currentlySelectedIds.includes(id);
            var isUnselectedNodeHovered = type !== EntityType.OTHER && !currentlySelectedIds.includes(id);
            var cursor = void 0;
            if (isSelectedNodeHovered) {
                cursor = isMultiSelecting ? "pointer" : "move";
                hoveredId !== id && setHoveredId(id);
            }
            else if (isUnselectedNodeHovered) {
                cursor = "pointer";
                hoveredId !== id && setHoveredId(id);
            }
            else {
                cursor = "auto";
                hoveredId && setHoveredId(undefined);
            }
            document.body.style.cursor = cursor;
            return;
        }
        if (!initialY || !picked)
            return;
        if (picked.type !== EntityType.NODE)
            return;
        if (!isEntityMoving) {
            setIsEntityMoving(true);
        }
        var initialZDistance = cameraEntity.getPosition().y - initialY;
        var pos = cameraEntity.camera.screenToWorld(eventX, eventY, initialZDistance);
        /**
         * Pos is calculated based on the initial distance from camera to picked point,
         * which means that pos.y will increase as the user drags the area further from
         * the initial point. As we want the picked point to stay at the initial y value, we
         * recalculate the position using the equation of the line passing betwen the
         * camera location and the position returned from screenToWorld.
         *
         * Direction vector <L, M, N> = <x1-x2, y1-y2, z1-z2>
         * x(t) = x1 + Lt
         * y(t) = y1 + Mt
         * z(t) = z1 + Nt
         **/
        var l = pos.x - cameraEntity.getPosition().x;
        var m = pos.y - cameraEntity.getPosition().y;
        var n = pos.z - cameraEntity.getPosition().z;
        /*
         * We know the y value of the position, so we can calculate t and then
         * x and z
         */
        var t = (initialY - pos.y) / m;
        var x = pos.x + l * t;
        var z = pos.z + n * t;
        // Create a map of all entities that are picked or currently selected, and their positions
        var entityPositionMap = new Map();
        var pickedEntity = picked.entity;
        var pickedPosition = pickedEntity.getPosition().clone();
        entityPositionMap.set(picked.entity, pickedPosition);
        var selectedEntities = currentlySelectedIds
            .map(function (id) { return app.root.findByName(id); })
            .filter(function (entity) { return entity !== null; });
        selectedEntities.forEach(function (entity) {
            var position = entity.getPosition().clone();
            entityPositionMap.set(entity, position);
        });
        // Calculate the movement offset from the picked entity
        var offsetX = x - pickedPosition.x;
        var offsetY = initialY - pickedPosition.y;
        var offsetZ = z - pickedPosition.z;
        // update the position of all entities with the same offset
        entityPositionMap.forEach(function (position, entity) {
            entity.setPosition(position.x + offsetX, position.y + offsetY, position.z + offsetZ);
        });
    }, [
        app,
        picker,
        selectionRectangle,
        picked,
        initialY,
        isEntityMoving,
        currentlySelectedIds,
        updateSelection,
        pick,
        isMultiSelecting,
        hoveredId,
    ]);
    useEffect(function () {
        if (!app)
            return;
        var canvas = app.graphicsDevice.canvas;
        setPicker(new pc.Picker(app, canvas.clientWidth, canvas.clientHeight));
    }, [app]);
    useEffect(function () {
        if (!picker || !app)
            return;
        var cameraEntity = app.root.findByName("Camera");
        if (!cameraEntity || !cameraEntity.camera)
            return;
        var mouseMove = function (event) {
            return onMouseMove(event.x, event.y, cameraEntity);
        };
        var mouseDown = function (event) {
            onMouseDown(event, cameraEntity.camera);
        };
        var mouseUp = function () { return onMouseUp(cameraEntity.camera); };
        if (!isDisabled) {
            app.mouse.on(pc.EVENT_MOUSEDOWN, mouseDown);
            app.mouse.on(pc.EVENT_MOUSEMOVE, mouseMove);
            app.mouse.on(pc.EVENT_MOUSEUP, mouseUp);
        }
        return function () {
            app.mouse.off(pc.EVENT_MOUSEDOWN, mouseDown);
            app.mouse.off(pc.EVENT_MOUSEMOVE, mouseMove);
            app.mouse.off(pc.EVENT_MOUSEUP, mouseUp);
        };
    }, [picker, app, onMouseDown, onMouseMove, onMouseUp, isDisabled]);
    var movingNodeIds = useMemo(function () {
        if (!isEntityMoving)
            return [];
        var result = __spreadArray([], __read(currentlySelectedIds), false);
        if (!(picked === null || picked === void 0 ? void 0 : picked.id) || result.includes(picked.id))
            return result;
        result.push(picked.id);
        return result;
    }, [currentlySelectedIds, isEntityMoving, picked === null || picked === void 0 ? void 0 : picked.id]);
    return {
        picker: picker,
        setPickerDisabled: setPickerDisabled,
        movingNodeIds: movingNodeIds,
        hoverId: hoveredId,
        selectionRectangle: selectionRectangle,
    };
};
/**
 * Gets a picker area from a selection rectangle.
 * Can handle cases where the user drags the selection rectangle "the wrong way",
 * I.E. from right to left or from bottom to top.
 * The picker area can be used to pick entities with pc.Picker.
 */
export function getPickerArea(selectionRectangle) {
    var x = Math.min(selectionRectangle.from.x, selectionRectangle.to.x);
    var y = Math.min(selectionRectangle.from.y, selectionRectangle.to.y);
    var width = Math.abs(selectionRectangle.to.x - selectionRectangle.from.x);
    var height = Math.abs(selectionRectangle.to.y - selectionRectangle.from.y);
    return { x: x, y: y, width: width, height: height };
}
/**
 * Prepares the picker to pick entities in the scene.
 */
function preparePicker(picker, app, camera) {
    var canvas = app.graphicsDevice.canvas;
    picker.resize(canvas.clientWidth, canvas.clientHeight);
    picker.prepare(camera, app.scene);
}
