import { useEffect } from "react";
import { allowEdgeBetween } from "3d/rules";
import { useAppSelector, selectors } from "store";
import { fadeEntity, emphasizeEntity, resetMaterial } from "3d/helpers";
export var useInteractiveAppearance = function (id, entity, materialAssets, hoverId) {
    var selectedId = useAppSelector(selectors.selectSelectedId);
    var nodes = useAppSelector(selectors.selectNodes);
    var attachments = useAppSelector(selectors.selectAttachments);
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var selectedIds = useAppSelector(selectors.selectSelectedIds);
    useEffect(function () {
        if (!entity)
            return;
        var isNode = !!nodes[id];
        var isEdge = !isNode;
        /* If an attachment has been selected and this is a node, the node
         * should only be faded if there is no available attachments to
         * draw an edge to */
        if (selectedId && attachments[selectedId] && isNode) {
            var selectedAttachment_1 = attachments[selectedId];
            var thisNode = nodes[id];
            var canConnectToThisNode = thisNode.attachmentIds.some(function (id) {
                return allowEdgeBetween(attachments[id], selectedAttachment_1);
            });
            if (!canConnectToThisNode) {
                fadeEntity(entity);
            }
        }
        else if (selectedIds.length > 0 &&
            !selectedIds.includes(id) &&
            hoverId !== id) {
            fadeEntity(entity);
        }
        else if (hoverId === id) {
            emphasizeEntity(entity);
        }
        return function () {
            resetMaterial(entity, materialAssets);
        };
    }, [
        selectedId,
        hoverId,
        id,
        materialAssets,
        entity,
        attachments,
        nodes,
        isCentralizedSystem,
        selectedIds,
    ]);
};
